export function Footer(params) {
    return (
        <footer>
            <div className="container container-footer">
                <div className="regular">
                    <img alt="" src="/images/webp/logoXO.webp" />
                    <p>
                        170 bureaux de la colline 92213 Saint Cloud Cedex France
                    </p>
                    <p>Tél. : <a href="tel:0185742013">01 85 74 20 13</a></p>
                    <br/>
                    <p>PT_LOX_50_01-012023</p>
                    <p>N° de visa : 23/01/69198890/PM/001</p>
                </div>
                <div className="light">
                    <p>
                        Société par actions simplifiée
                    </p>
                    <p>Numéro Siren : 813935863</p>
                    <p>Numéro Siret : 81393586300031 (siège de l'entreprise)</p>
                    <p>
                        Numéro TVA intracommunautaire : FR23813935863 
                    </p>
                    <p>Greffe : RCS Nanterre </p>
                    <p>
                    Code NAF / APE : 21102 (fabrication de
                        produits pharmaceutiques de base)
                    </p>
                    <p>Date d'immatriculation : 05/10/2015</p>
                    <p>Capital social : 22 440 134,31€</p>
                </div>
                <div className="light">
                    <p>
                        Représentée par Karine PINON, Directrice Générale et
                        Pharmacien Responsable de Laboratoire X.O
                    </p>
                    <p>©Laboratoire X.O <br/>  Dernière mise à jour : janvier 2023</p>
                    <p>
                        En cas de signalement de cas de pharmacovigilance, de
                        défaut qualité ou de demande d’information médical, nous
                        vous remercions de contacter le : 01 41 12 18 83 ou
                        pvlaboratoirexo@universalmedica.com
                    </p>
                </div>
            </div>
        </footer>
    );
}
