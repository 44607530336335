const NodeQuiz1 = {
  question : "Quelle est votre prescription à l'issue de la consultation ?",
  type : "solo", // solo / multiple
  style : "vertical",  // vertical - horizontal
  options : [
    {id:"1",text : "A. Je renouvelle l'ordonnance; il vient au demeurant pour cela",correct : false},
    {id:"2",text : "B. Je renouvelle l'ordonnance car la PA est probablement l'objectif",correct : false},
    {id:"3",text : "C. On ne change pas une équipe qui gagne",correct : false},
    {id:"4",text : "D. J'ai suffisamment d'arguments pour passer une bithérapie",correct : true},
  ],
  actions : [
    {text : "Revoir la présentation du cas",divert : "NodeVideoCase1_1"},
    {text : "VALIDER"},

  ]
}
const NodeQuiz2 = {
  question : "A votre avis, quelle est la proportion de patients hypertendus non dépistés en France ?",
  type : "solo", // solo / multiple
  style : "vertical",  // vertical - horizontal
  options : [
    {id:"1",text : "A. 1 sur 10",correct : false},
    {id:"2",text : "B. 1 sur 4",correct : false},
    {id:"3",text : "C. 1 sur 2",correct : true},
  ],
  actions : [
    {text : "Revoir la présentation du cas",divert : "NodeVideoCase2_3"},
    {text : "VALIDER"},
  ]
}
const NodeQuiz3 = {
  question : "Seulement ... % des hypertendus sont contrôlés en France",
  type : "solo", // solo / multiple
  style : "horizontal",  // vertical - horizontal
  options : [
    {id:"1",text : "0%",correct : false},
    {id:"2",text : "25%",correct : false},
    {id:"3",text : "50%",correct : true},
    {id:"4",text : "75%",correct : false},
    {id:"5",text : "100%",correct : false},
  ],
  actions : [
    {text : "Revoir la présentation du cas",divert : "NodeVideoCase3_3"},
    {text : "VALIDER"},
  ]
}
const NodeQuiz4 = {
  question : "À votre avis que faut-il faire ?",
  type : "solo", // solo / multiple
  style : "vertical",  // vertical - horizontal
  options : [
    {id:"1",text : "A. Modifier le dosage du traitement actuel en monothérapie",correct : false},
    {id:"2",text : "B. Passer en bithérapie",correct : true},

  ],
  actions : [
    {text : "Revoir la présentation du cas",divert : "NodeVideoCase4_3"},
    {text : "VALIDER"},
  ]
}

const NodeQuiz5 = {
  question : "Avez-vous trouvé cette formation utile ?",
  type : "solo", // solo / multiple
  style : "horizontal",  // vertical - horizontal
  options : [
    {id:"1",text : "0",src : "/interactive/button/stars_0.png"},
    {id:"2",text : "1.5",src : "/interactive/button/stars_1.png"},
    {id:"3",text : "3",src : "/interactive/button/stars_2.png"},
  ],
  actions : [
    {text : "VALIDER"},
  ]
}


// video interactive 2

const NodeQuiz6 = {
  question : "L'HTA gravidique (Plusieurs réponses possibles)",
  type : "mutiple", // solo / multiple
  style : "vertical",  // vertical - horizontal
  options : [
    {id:"1",text : "A. Augmente le risque d'HTA permanente",correct : true},
    {id:"2",text : "B. Augmente le risque de diabète en post partum",correct : false},
    {id:"3",text : "C. Doit être considérée comme un facteur de risque cardio vasculaire",correct : true},
    {id:"4",text : "D. L'HTA disparaît toujours après l'accouchement sans risque d'HTA chronique",correct : false},
  ],
  actions : [
    {text : "Revoir la présentation du cas",divert : "NodeVideoCase1_1"},
    {text : "VALIDER"},
    {text : "Qu'est-ce que l'HTA gravidique ?",divert : "NodeVideoCase_gravidique1_1"},
  ]
}


const NodeQuiz7 = {
  question : "Faut-il une consultation post-partum ?",
  type : "solo", // solo / multiple
  style : "vertical",  // vertical - horizontal
  options : [
    {id:"1",text : "A. OUI",correct : true},
    {id:"2",text : "B. NON",correct : false},
  ],
  actions : [
    {text : "Revoir la présentation du cas",divert : "NodeVideoCase_revoire_1_1"},
    {text : "VALIDER"},

  ]
}




const NodeQuiz8 = {
  question : "Quelle est votre prescription à l’issue de la seconde consultation ?",
  type : "solo", // solo / multiple
  style : "vertical",  // vertical - horizontal
  options : [
    {id:"1",text : "A. La PA est normale, pas de suivi",correct : false},
    {id:"2",text : "B. La PA est limite, les mesures hygiéno diététiques suffiront",correct : false},
    {id:"3",text : "C. Elle est à risque de développer une HTA, je prévois une automesure sur 3 jours",correct : true},
    {id:"4",text : "D. Je reprends tout de suite le traitement anti HTA",correct : false},
  ],
  actions : [
    {text : "Revoir la présentation du cas",divert : "NodeVideoCase_revoire_2_1"},
    {text : "VALIDER"},
  ]
}


const NodeQuiz9 = {
  question : "Avez-vous trouvé cette formation utile ?",
  type : "solo", // solo / multiple
  style : "horizontal",  // vertical - horizontal
  options : [
    {id:"1",text : "0",src : "/interactive/button/stars_0.png"},
    {id:"2",text : "1.5",src : "/interactive/button/stars_1.png"},
    {id:"3",text : "3",src : "/interactive/button/stars_2.png"},
  ],
  actions : [
    {text : "VALIDER"},
  ]
}

const data = {
  interactive_quiz_1 : {
    NodeQuiz1,
    NodeQuiz2,
    NodeQuiz3,
    NodeQuiz4,
    NodeQuiz5
  },
  interactive_quiz_2 : {
    NodeQuiz6,
    NodeQuiz7,
    NodeQuiz8,
    NodeQuiz9
  }

}

export default data
