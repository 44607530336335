export function flexFontStyle () {
  
    function flexFont () {
      var divs = document.getElementsByClassName("flexFont");
    for(var i = 0; i < divs.length; i++) {
      let ratio = 0.05 ;
        var relFontsize = divs[i].offsetWidth*ratio;
        divs[i].style.fontSize = relFontsize+'px';
    }
    }
    flexFont();
    window.onload = function(event) {
      flexFont();
    };
    window.onresize = function(event) {
      flexFont();
    };
    window.addEventListener("orientationchange",() => {
      flexFont();
    })
  };
  