import { Howl } from "howler";

// export const countdownDie = new Howl({
//     src : ["/HeyHey.mp3"],
// });

class countdownDieClass {
    constructor() {
        this.once = false ;
        this.howl = new Howl({
            src : ["/HeyHey.mp3"],
        })
    }
    play (cb = () => {}) {
        if(!this.once) {
            this.howl.play();
            cb();
            return true ;
        }
        return false ;
    }
    onend(cb = () => {}) {
        this.howl.on("end",() => {cb();this.once = true})
        return this ;
    }
    getHowl() {
        return this.howl ;
    }
    stop (cb = () => {}){
        this.howl.stop();
        cb();
    }
    reset() {
        this.once = false ;
    }
    
}
export const countdownDie = new countdownDieClass();

export const soundFx = new Howl({
    "src": [
      "/sound/output/effects.webm",
      "/sound/output/effects.mp3"
    ],
    "sprite": {
      "click": [
        0,
        1044.8979591836735
      ],
      "correctAnswer": [
        3000,
        2620.294784580499
      ],
      "tickloop": [
        7000,
        884.2403628117914
      ],
      "wrongAnswer": [
        9000,
        2156.167800453515
      ]
    }
  })

export const relVarAmbiance = new Howl({
  "src": [
    "/sound/relvar/ambiance.webm",
  ],
  loop : true,
  volume : 0.3,
})

export const relVarSound = new Howl({
  "src": [
    "/sound/relvar/effects.webm",
    "/sound/relvar/effects.mp3"
  ],
  "sprite": {
    "fermer": [
      0,
      9451.451247165533
    ],
    "inhalation1": [
      11000,
      5456.961451247167
    ],
    "inhalation2": [
      18000,
      10081.519274376418
    ],
    "inhalation3": [
      30000,
      1294.8752834467107
    ],
    "inhalation4": [
      33000,
      12625.034013605444
    ],
    "inspirer5": [
      47000,
      8647.95918367347
    ],
    "menu1": [
      57000,
      4560.975056689344
    ],
    "menu2": [
      63000,
      3422.1541950113306
    ],
    "menu3": [
      68000,
      3283.446712018147
    ],
    "ouvrir": [
      73000,
      6520.612244897961
    ]
  }
})