export const getCircularReplacer = () => {
    const seen = new WeakSet();
    return (key, value) => {
      if (typeof value === "object" && value !== null) {
        if (seen.has(value)) {
          return;
        }
        seen.add(value);
      }
      return value;
    };
  };
  
  export function existeInArray(find,findIn) {
    var index = findIn.indexOf(find);
    return index !== -1 ;
}