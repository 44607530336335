import { useEffect, useRef, useState } from "react"
import Button from "./Button"

export const TimerButton = ({endTime=5000,onClick = () => {},pause,children,...props}) => {
    const interval = 200 ;
    const timerRef = useRef(null);
    const [time,setTime] = useState(0);

    function startInterval() {
        timerRef.current = setInterval(() => {
            setTime((t) => t + interval) ;
        }, interval);
    }
    function stopInterval() {
        timerRef.current && clearTimeout(timerRef.current);
        timerRef.current = null ;
    }



    useEffect(() => {
        if(pause === true) {
            stopInterval();
        } else {
            startInterval();
        }
        return () => {
            stopInterval();
        }
    },[pause]);

    useEffect(() => {
        if(time >= endTime) {
            click();
        }
    },[time])


    function click() {
        timerRef.current && clearTimeout(timerRef.current);
        timerRef.current = null ;
        onClick();
    }

    return <Button {...props} onClick={click}>
        
        {children}
        <div className="button-timer-layer" style={{width : `${(100 - ( time / endTime) * 100)}%`,transition : `width ${interval}ms linear`}}></div>

    </Button>
}