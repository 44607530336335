import { useState } from "react";
import { soundFx } from "../../data/sound";

const Option = ({children,className="",style={},selected=false,onClick = () => {}}) => {
    const _onClick = () => {
        let select = !selected
        onClick(select);
    }
    return <div className={`btn buttonContainer ${className} ${selected ?  "selected" : ""}`} style={{...style}} onClick={() => {soundFx.play("click");_onClick()}}>{children}</div>;
  };

export default Option ;