import { Link } from "react-router-dom";

const cover = "/images/webp/video1Cover.webp" ;
export function VideoThumbnail({coverImage = cover,playButton}) {
    return <div className="video-thumbnail-container">
            <img alt="" className="showup video-thumbnail-cover" src={coverImage}/>
            {playButton && <img alt="" className="clickable video-thumbnail-playButton" src={playButton}/>}
    </div>
}

export function VideoThumbnailExtern({href="#", newTab=true,coverImage = cover,playButton}) {
    return <div className="video-thumbnail-container">
        <Link target={newTab && "blank" } to={href}> 
            <img alt="" className="showup video-thumbnail-cover" src={coverImage}/>
            {playButton && <img alt="" className="clickable video-thumbnail-playButton" src={playButton}/>}
        </Link>
    </div>
}