import { Link } from "react-router-dom";
import { Hero } from "../container/Hero";
import { Main } from "../container/Main";
import { VideoGallery } from "../container/VideoGallery";
import { videoDataInteractive } from "../assets/data/video";

export function Home() {
    return (
        <>
            {/* <div>
                ok
            </div> */}
            <Main className={"marged"}>
                <div className="container">
                    <Hero />
                    <VideoGallery data={videoDataInteractive}/>

                    <section className="extra-info-typo">
                        <h4>Références :</h4>
                        <p className="light">
                            1 : Fédération Française de Cardiologie (FFC).
                            Hypertension artérielle : premier facteur de risque
                            cardio-vasculaire. Brochure, 2020.
                        </p>
                        <p className="light">
                            2 : Olié V., Blacher J., Vallée A. La revue du
                            Praticien. Dossier - Hypertension artérielle : le
                            besoin d'un second souffle. Prise en charge de l'HTA
                            en France : où en est-on ? déc. 2019.{" "}
                            <a rel="noreferrer" href="https://www.larevuedupracticien.fr" target="_blank">https://www.larevuedupracticien.fr</a>
                        </p>
                        <br />
                        <br />
                        <p className="light">Retrouvez <b><Link to={"/rcp"}>ici</Link></b> les caractéristiques produit</p>
                    </section>
                </div>
            </Main>
        </>
    );
}
