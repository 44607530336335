import { useState } from "react";
import Button from "../action/Button";
import Option from "../action/option";

export function QuizContainer({
    question = "question default",
    options = [{ text: "option 1", correct: false }],
    review = () => {},
    actions = [{text : "validate",divert : "next"}],
    type = "solo",
    next = () => {},
    style = "vertical"
}) {


    const [selected,setSelected] = useState(new Array(options.length).fill(false));
    const [submitted,setSubmitted] = useState(false);
    function _handleSelection(index,value) {
        if(type === "solo") {
            let newSelected = selected.map((s,i) => index === i ? value : false);
            setSelected(newSelected);
        }else {
            let newSelected = selected.map((s,i) => index === i ? value : s);
            setSelected(newSelected);
        } 
    }
    function _handleAction(divert) {
        let index = selected.indexOf(true);
        let submit = divert ? false : true;
        if(submit && index === -1) return ;
        if(submit) {
            setSubmitted((s) => !s)
            console.log(selected);
            setTimeout(() => {
                let index = selected.indexOf(true);
                window.gtag('event', 'quiz_response',{
                    question_id : question,
                    answer_id : options[index].text
                  });
                next();

            }, 700);
        } else {
            next(divert);
        }
    }
    function colorOption(selected,correct,submitted) {
        if(correct === undefined) return "primary";
        if(!selected) return "primary" ;
        if(!submitted) return "primary" ;
        if(submitted && selected) {
            if(correct) return "success"
            else return "danger"
        }
    }
    return (
        <div className={`quizContainer ${style}`}>
            <h1 className="quizQuestion">{question}</h1>
            <div className="quizAnswers">
                {options.map((option, i) => {
                    return (
                        <Option
                            key={i}
                            onClick={(selected) => _handleSelection(i,selected)}
                            className={`outline ${colorOption(selected[i],option.correct,submitted)}`}
                            selected = {selected[i]}
                        >
                            {option["src"] === undefined ? option.text : <img alt="" src={option.src}/> }
                            
                        </Option>
                    );
                })}
            </div>
            <div className="quizActions">
                {
                   !submitted && actions.map((action) => {
                        return  <Button onClick={() => _handleAction(action["divert"])} className="fill danger">{action.text}</Button>
                    })
                }
            </div>
        </div>
    );
}
