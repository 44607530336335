import { useState, useEffect } from 'react';
import { soundFx } from '../video_interactive/data/sound';

export default function useLongPress({callback = () => {}, onFail = () => {}, withPast = false, update = () => {},threshold = 5,updateSpeed = 0.2,enable = true}) {
  const [startLongPress, setStartLongPress] = useState(null);
  const [currentState,setCurrentState] = useState(0);
  useEffect(() => {
    let interval = setInterval(() => {
        if(startLongPress === true) setCurrentState((el) => el !== threshold ?   el + (1 * updateSpeed) : el ) ;
        if(startLongPress === false) setCurrentState((el) => {if(el <= 0 || !withPast ) {clearInterval(interval); onFail(); return 0} else return el - (1 * updateSpeed)}) ;
    },1000 * updateSpeed)
    return () => {
        clearInterval(interval) ;
    }  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[startLongPress])
  useEffect(() => {
      update(currentState);
      if(currentState >= threshold && startLongPress) {soundFx.play("correctAnswer"); callback();}
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[currentState,update]);
  return {
    onMouseDown: () => enable && setStartLongPress(true),
    onMouseUp: () => enable && setStartLongPress(false),
    // onMouseLeave: () => enable && setStartLongPress(false),
    onTouchStart: () => enable && setStartLongPress(true),
    onTouchEnd: () => enable && setStartLongPress(false),
  };
}