/* eslint-disable */
import { relVarSound } from '../data/sound'
import NodeFactory from './nodeFactory'
import { existeInArray } from './utils'

export default class nodeHandler {
  constructor(template) {
    var _template = template

    this.getTemplate = function () {
      return _template
    }
    this.flags = []
    this.history = []
    this.currentScene = [] // current displayed node
    this.observers = []
    this.initial = template.data.initial
    this.currentNode = template.data.initial
    this.progressState = []
    this.status = 'ready'
    this.loadState() ;
    this.sounds = [] ;
    this.garbageCollector();
  }
  garbageCollector = () => {
    setTimeout(() => {
    if(this.currentScene.length > 4) {
      this.currentScene.shift();
    }
  }, 2000);
  }
  saveState = () => {
    const state = Object.assign(
      {},
      { initial: this.initial },
      { flags: this.flags },
      { history: this.history },
      { progressState: this.progressState },
      { status: this.status }
    )
    // localStorage.setItem('interactive', JSON.stringify(state))
  }
  loadState = () => {
    const state = JSON.parse(localStorage.getItem('interactive'))
    if(state && state.status === "end") return ;
    if (state) {
      this.history = state.history
      this.initial = state.initial
      this.flags = state.flags
      this.currentNode = state.initial
      this.progressState = state.progressState
    }
  }
  clearState = () => {
    localStorage.removeItem('interactive')
  }
  pauseAllSounds = () => {
    this.sounds.forEach((el) => {
        relVarSound.pause(el);
    });
  }
  resumeAllSounds = () => {
    this.sounds.forEach((el) => {
      relVarSound.play(el);
  });
  }
  playSound = (sprite) => {
    if (!this.sounds.includes(sprite)) {
      this.sounds.push(sprite);
    }
  }
  stopSound = (sprite) => {
    this.sounds = this.sounds.filter((sound) => sprite !== sound ) ;
  }
  updateInitial = (key) => {
    this.initial = key
    this.saveState()
  }
  ifCondition = (flags) => {
    return flags.every((elem) => elem === "" ? true : this.flags.includes(elem))
  }
  notIfConditions = (flags) => {
    let result = flags.every((elem) => !this.flags.includes(elem))
    return result ;
  }
  progress = (key) => {
    if (!existeInArray(key, this.progressState)) this.progressState.push(key)
    return this.progressState
  }
  updateFlag = (flags) => {
    if (!Array.isArray(flags)) {
      console.error(flags, 'must be an array')
    }
    flags.forEach((flag) => {
      if (!this.isFlagExist(flag)) {
        this.addFlag(flag)
        console.log(`add flag ${flag}`)
      } else {
        console.log(`this flag ${flag} already exist`)
      }
    })
  }
  addFlag = (flag) => {
    this.flags.push(flag)
    return this.flags
  }
  isFlagExist = (flag) => {
    var index = this.flags.indexOf(flag)
    return index !== -1
  }
  getNode = (keyNode) => {
    const key = keyNode || this.currentNode
    const template = this.getTemplate()
    return {
      key,
      data: template.data.nodes[key]
    }
  }
  getNodeSchema = (keyNode) => {
    const key = keyNode || thcreates.currentNode
    const template = this.getTemplate()
    return template.data.nodes[key]
  }
  createNode = (keyNode) => {
    const node = this.getNode(keyNode)
    return new NodeFactory(node, this)
  }
  nodeEnter = (node) => {
    node.beforeInvoke()
    if (node !== null) {
      this.currentScene.push(node)
      this.status = 'playing'
    }
    node.onInvoke();
    this.notifyAllObservers()
  }
  nextNode = () => {}
  declareEnd = (nodeKey) => {
    if (nodeKey === null) {
      this.status = 'end'
      this.saveState()
      this.notifyAllObservers()
    }
  }
  clear = () => {
    this.currentScene = []
  }
  nodeExit = (uniqueId) => {
    if (uniqueId) {
      this.currentScene = this.currentScene.filter(
        (el) => el.uniqueId !== uniqueId
      )
    } else {
      this.currentScene.shift()
    }
    this.notifyAllObservers()
  }

  // Observer functions
  subscribeObserver = (observer) => {
    var index = this.observers.indexOf(observer)
    if (index === -1) {
      this.observers.push(observer)    
      return
    }
    console.error(
      `this observer ${observer.name} exist in observers ${this.observers}`
    )
  }
  unsubscribeObserver = (observer) => {
    var index = this.observers.indexOf(observer)
    if (index > -1) {
      this.observers.splice(index, 1)
    }
    }
  notifyObserver = (observer) => {
    var index = this.observers.indexOf(observer)
    if (index > -1) {
      this.observers[index]('data')
    }
  }
  notifyAllObservers = (event = 'default') => {
    for (let index = 0; index < this.observers.length; index++) {
      this.observers[index]([
        event,
        this.currentScene,
        this.progressState,
        this.status
      ])
    }
  }
}

