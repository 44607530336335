import { videoDataInteractive } from "../assets/data/video";
import { VideoGallery } from "../container/VideoGallery";
import { Link } from "react-router-dom";

export function InteractifPage() {
    return (
        <div className="container">
          <VideoGallery data={videoDataInteractive}/>
          <section className="extra-info-typo">
                        <p className="light">Retrouvez <b><Link to={"/rcp"}>ici</Link></b> les caractéristiques produit</p>
          </section>
        </div>
    );
  }