export function Hero({}) {
    return (
        <section className="Hero-container">
            <div className="Hero-text-container">
                <h1>Traitement de l'Hypertension Artérielle</h1>
                <p className="text-1 light">
                    L'hypertension artérielle est le <b>1<sup>er</sup> facteur de risque
                    cardio-vasculaire<sup>1</sup></b> et pourtant <b>près de la moitié des
                    hypertendus ne sont toujours pas dépistés en France<sup>2</sup></b> ...
                </p>
                <p className="text-2 light">
                    Vous allez ici découvrir des e-cas cliniques présentés lors
                    de notre webinar du 09 novembre 2022 et répondre aux
                    questions qui vous seront posées.
                </p>
                <p className="text-2 light">
                    Chacun d'entre eux aborde le thème du dépistage et de la
                    prise en charge des patients hypertendus méconnus.
                </p>
            </div>
            <div className="Hero-image-container">
                <img className="float" src="/images/webp/heart.webp" alt="heart" />
            </div>
        </section>
    );
}
