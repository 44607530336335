import ReactPlayer from "react-player";
import {
  VideoThumbnail,
  VideoThumbnailExtern,
} from "../component/VideoThumbnail";

export function VideoCard({
  title = "title",
  description = "description",
  videoUrl = "/videos/WeAreGoingOnBullrun.mp4",
  thumbnail = {},
}) {
  return (
    <div className="video-card">
      <h3 className="video-title regular">{title}</h3>
      <div className="video-container">
        {videoUrl ? (
          <ReactPlayer
            url={videoUrl}
            width="100%"
            height="100%"
            controls={true}
            light={true}
            playing={true}
            playIcon={<VideoThumbnail {...thumbnail} />}
          />
        ) : (
          "videoUrl"
        )}
      </div>
      <p className="video-description regular">
        <span>{description}</span>
      </p>
    </div>
  );
}

export function InteractiveCard({
  href = "#",
  title = "title",
  description = "extern video",
  thumbnail = {},
}) {
  return (
    <div className="video-card">
      <h3 className="video-title regular">{title}</h3>
      <div className="video-container">
        <VideoThumbnailExtern href={href} {...thumbnail} />
      </div>
      <p className="video-description regular">
        <span>{description}</span>
      </p>
    </div>
  );
}
