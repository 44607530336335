import { useEffect } from "react";

export function RcpPage() {
  useEffect(() => {
    window.scrollTo(0,0);
  },[])
    return (
        <div className="container">
          <article className="rcp">
            <section>
                <h3>CONTRE-INDICATIONS :</h3>
                <p>hypersensibilité à la substance active ou à l'un des excipients mentionnés à la rubrique « Liste des excipients » du RCP, sténose aortique sévère.</p>
            </section>

            <section>
                <h3>MISES EN GARDE SPÉCIALES ET PRÉCAUTIONS D’EMPLOI :</h3>
                <p>ce médicament contient du saccharose. Son utilisation est déconseillée chez les patients présentant une intolérance au fructose, un syndrome de
malabsorption du glucose et du galactose ou un déficit en sucrase/isomaltase. <b>Insuffisance hépatique :</b> la nicardipine doit être utilisée avec prudence chez les
patients présentant une insuffisance hépatique . <b>Insuffisance rénale :</b> la nicardipine doit être utilisée avec prudence chez les patients présentant une
insuffisance rénale (voir Propriétés pharmacocinétiques). <b>Pression artérielle :</b> il est recommandé de prendre les précautions nécessaires pour éviter une chute
excessive de la pression artérielle. <b>Association avec des bêta-bloquants :</b> des précautions sont nécessaires lors de l’administration de la nicardipine en
association avec un bêta-bloquant chez les patients présentant une diminution de la fonction cardiaque. <b>Maladie cardiaque ischémique :</b> le traitement par des
dihydropyridines de courte durée d’action peut être associé à un risque accru d’événements cardiovasculaires ischémiques. <b>Insuffisance cardiaque :</b> la
nicardipine doit être utilisée avec précaution chez les patients atteints d’insuffisance cardiaque, en raison du risque d’aggravation de l’insuffisance cardiaque.
<b>Œdème pulmonaire aigu lors de la grossesse :</b> la nicardipine doit être administrée avec précaution chez les femmes enceintes, des cas d’œdèmes pulmonaires
aigus ayant été rapportés durant la grossesse. Les femmes enceintes doivent faire l’objet d’une surveillance étroite afin de déceler l’apparition éventuelle d’un
œdème pulmonaire aigu. En cas de survenue d’un œdème pulmonaire aigu, il convient d’arrêter immédiatement le traitement par la nicardipine et d’instaurer
un traitement approprié.</p>
            </section>
            <section>
                <h3>GROSSESSE ET ALLAITEMENT :</h3>
                <p><b>Grossesse :</b> les études réalisées chez l’animal n’ont pas mis en évidence d’effet tératogène, mais une diminution du gain de poids à la naissance et une
inhibition de la croissance pondérale post-natale ont été rapportées (voir rubrique « Données de sécurité préclinique »). En l’absence d’effet tératogène chez
l’animal, un effet malformatif dans l’espèce humaine n’est pas attendu. En effet, à ce jour, les substances responsables de malformations dans l’espèce
humaine se sont révélées tératogènes chez l’animal au cours d’études bien conduites sur deux espèces. Il n’existe pas actuellement de données en nombre
suffisant pour évaluer un éventuel effet malformatif ou fœtotoxique de la nicardipine lorsqu’elle est administrée pendant la grossesse. Des cas d’œdèmes
aigus du poumon ont été rapportés lors de l’utilisation de la nicardipine comme agent tocolytique pendant la grossesse (voir rubriques « Mises en garde
spéciales et précautions d’emploi » et « Effets indésirables »), en particulier lors de grossesses multiples (gémellaires et plus), et en cas d’administration
intraveineuse et/ou d’utilisation concomitante de bêta-2 agonistes. En conséquence, par mesure de précaution, il est préférable de ne pas utiliser la nicardipine
pendant la grossesse. <b>Allaitement :</b> la nicardipine passe dans le lait maternel. En conséquence, la nicardipine ne doit pas être utilisée pendant l’allaitement (voir
rubrique « Données de sécurité préclinique »).</p>
            </section>

            <section>
                <h3>EFFETS INDÉSIRABLES :</h3>
                <p>les effets indésirables rapportés sont généralement bénins et peuvent nécessiter occasionnellement un ajustement de la dose ou plus rarement un arrêt du
traitement. <u>Affections du système nerveux :</u> fréquent : sensations vertigineuses, céphalées. <u>Affections cardiaques :</u> fréquent : palpitations. <u>Affections vasculaires :</u>
fréquent : bouffées vasomotrices. <u>Troubles généraux et anomalies au site d’administration :</u> fréquent : œdèmes périphériques.</p>
<br/>
<p>Pour plus d’information sur les effets indésirables peu fréquents ou rares consultez le résumé des caractéristiques du produit.
<b>Déclaration des effets indésirables suspectés :</b> Déclarez immédiatement tout effet indésirable suspecté d’être dû à un médicament à votre Centre régional de
pharmacovigilance (CRPV) ou sur www.signalement.social-sante.gouv.fr</p>
            </section>


            <section>
                <p><b>TITULAIRE DE L’AUTORISATION DE MISE SUR LE MARCHÉ :</b> Laboratoire X.O – 170 Bureaux de la Colline – 92213 SAINT-CLOUD Cedex - Tél : 01 85 74 20 13.
CONDITIONS DE PRESCRIPTION ET DE DÉLIVRANCE : Liste I. Remboursement Sécurité Sociale 65% - Agréé collectivités.</p>
              <p><b>Avant de prescrire, consultez la place dans la stratégie thérapeutique sur www.has-sante.fr</b></p>
              <p>Conformément aux dispositions de la réglementation applicable en matière de protection des données (en ce y compris le Règlement 2016/679/UE et la loi
n°78-17 du 6 janvier 1978 modifiée) et sous réserve des conditions prévues par ladite réglementation pour l’exercice de ces droits vous disposez sur les
données vous concernant d’un droit d’accès, d’un droit de rectification, d’un droit à l’effacement, d’un droit à la limitation du traitement, d’un droit au retrait de
tout consentement que vous auriez donné, d’un droit à la portabilité sur les données personnelles que vous avez fournies, ainsi que du droit de définir des
directives relatives au sort de vos données en cas de décès. Vous disposez également d’un droit d’opposition au traitement de vos données à caractère
personnelles, y compris au profilage de vos données, pour des raisons tenant à votre situation particulière. Vous pouvez exercer ces droits par courrier postal
auprès du Laboratoire X.O, 170 Bureaux de la Colline 92213 Saint-Cloud Cedex ou par courriel : info@laboratoirexo.fr. Vous disposez, par ailleurs, du droit
d’introduire une réclamation auprès de la Commission nationale de l’informatique et des libertés (CNIL), notamment sur son site internet www.cnil.fr</p>
            </section>

            <section className="rcp-qrcode-container">
              <p className="rcp-qrcode-text">Pour une information complète, consultez le Résumé des Caractéristiques du Produit sur la base de données publique du médicamenten
              en flashant ce QR Code ou directement sur le site internet : <a href="http://base-donnees-publique.medicaments.gouv.fr" target="_blank" rel="noreferrer">http://base-donnees-publique.medicaments.gouv.fr</a></p>
              <img className="rcp-qrcode" alt="" src="/images/qrcode.png"/>
            </section>

            <section>
              <p>
              PT_LOX_50_01-012023 <br/>
              N° de visa : 23/01/69198890/PM/001

              </p>
            </section>
          </article>
        </div>
    );
  }