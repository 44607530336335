import { VideoNode, GatesNode, ImageNode, QuizNode, TunnelNode, TextNode, ActionNode } from "./node";
export default class NodeFactory {
  constructor(node, ...props) {
    const type = node.data.node_type;
    if (type === "video") return new VideoNode(node, ...props);
    if (type === "text") return new TextNode(node, ...props);
    if (type === "gate") return new GatesNode(node, ...props);
    if (type === "image") return new ImageNode(node, ...props);
    if (type === "quiz") return new QuizNode(node, ...props);
    if (type === "tunnel") return new TunnelNode(node, ...props);
    if (type === "action") return new ActionNode(node, ...props);
  }
}
