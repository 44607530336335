import Button from "./Button";
import ButtonImage from "./ButtonImage";
import { TimerButton } from "./TimerButton";
import HoldAction from "./holdAction";


function Action({onEnd = () => {},extra=[],style={},linkPath= null,external = false,image=false,text = "",actionType = "classic",pause=false}) {
    function onClick() {
        console.log(external,linkPath);
        if(linkPath) {
            if(external === false) {
                onEnd(linkPath)
                

            }else {
                window.open(linkPath, '_blank');
            }
        }else {
            onEnd()
        }
    }
    if(image && actionType === "classic") return <ButtonImage className={extra.join(" ")} style={style} onClick={onClick}><img className="scale-in-center" src={text} alt={text} />  </ButtonImage>  ;
    if(actionType === "classic") return <Button style={style} className={`${extra.join(" ")}`} onClick={onClick}>{text}</Button>  ;
    if(actionType === "TimerButton") return <TimerButton pause = {pause} style={style} className={`${extra.join(" ")}`} onClick={onClick}>{text}</TimerButton>  ;
    if(actionType === "hold") return <HoldAction onClick={onClick} text ={text}></HoldAction>
    return null ;
}

export default Action ;