import { useEffect, useRef, useState } from 'react';
import { useTimer } from 'react-timer-hook';
import Timer from '../components/Timer/Timer';
import { soundFx } from './sound';
var classNames = require('classnames');

function createTime(sec = 0) {
  const t = new Date();
  t.setSeconds(t.getSeconds() + sec) ;
  return t ;
}

const quiz1 = {
    type: "mono", // mono or multi
    question: "",
    countdown: false, // default false  
    time: "2", // in seconde default 5
    options: [
        { answer: "", correct: true },
        { answer: "", correct: false },
    ]
}


export default function Option({ onClick = () => { }, answer,style={},className="", correct, disable = false }) {
    return <div
        className={classNames(className,{disable})}
        style={{...style}}
        onClick={(e) => !disable && onClick(e,{correct,answer})}>
        {answer}
    </div>
}

export function Options ({data=[],disable=false,onClickOption = () => {} }) {
    return <div>
        {data.map((el) => {
            return <Option {...el} disable={disable} onClick ={onClickOption} />
        })}
    </div>
}

export function Quiz({}) {
    const {
        seconds,
        minutes,
        hours,
        days,
        isRunning,
        start,
        pause,
        resume,
        restart,
      } = useTimer({ expiryTimestamp : createTime(5), onExpire: () => console.log("ss") });
    return <>
              
      <Options data={[
        { answer: "hi", correct: true },
        { answer: "hello", correct: false },
      ]} 
      onClickOption={(e,data) => {
        const t = createTime(2); 
        restart(t) ;
      }}
      disable = {false}
      />
    <div onClick={() => pause()}>
    {minutes}:{seconds}
    </div>
    </>
}

export function CountdownDelay(props) {
  const [display,setDisplay] = useState(false)
  useEffect(() => {
    setTimeout(() => {
      setDisplay(true)
    },props.wait !== undefined ? props.wait : 0)
  },[])
  return !display ? null : <Countdown {...props} />
}

let soundId = null ;
export function Countdown({time,delayApparence = 0,timeoutAnimation,pauseState=false,onExpire = () => {}}) {
  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
  } = useTimer({ expiryTimestamp : createTime(parseInt(time)), onExpire});
  
  useEffect(() => {
    if(isRunning) {
      if(soundId === null) soundId = soundFx.play("tickloop");
    }else {
      if(soundId !== null) {
        soundFx.stop(soundId);
        soundId = null ;
      }
    }
    return () => {
      if(soundId !== null) {
        soundFx.stop(soundId);
        soundId = null ;
      }
    }
  },[isRunning])

  useEffect(() => {

    if(pauseState) {
      pause();
    }else {
      resume();
    }
  },[pauseState]);
  return <div className="timer">
    <Timer className={classNames("fade-in",{timeoutAnimation})} time={seconds}/>
  </div>
}
