/* eslint-disable */
import React from 'react'
import './Popup.css';


export default function Popup({
  next,
  children,
  Nobackground = false,
  jauge = true,
  style = {},
  buttonText,
  buttonStyle = {},
  button = true
}) {

  return (
    <>
      <div style={{ ...style }} className={Nobackground && 'Popup_container'}>
        {children}
      </div>
      <div className="advancebutton_video">
        <div
          className="horizonalBar d-flex"
          style={{ width: '50%', visibility: jauge ? 'visible' : 'hidden' }}
        >
          <div style={{ width: '100%', marginTop: '0px' }}>
            {' '}
            <div className="time" style={{ marginBottom: 0 }}>
              <div
                className="bar"
                style={{ width: 10 + '%' }}
              ></div>
            </div>
          </div>

          <div className="water_drops">
            <div className="">
              {/* <img
                ref={WateringRef}

                src={watering}
                alt=""
                style={{ width: '42px', marginTop: '-4px' }}
              /> */}
            </div>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            visibility: button ? 'visible' : 'hidden'
          }}
        >
          <button
            style={{ ...buttonStyle }}
            className="button__Lesson_Start"
            disabled=""
            onClick={next}
          >
            {buttonText}
          </button>
        </div>
      </div>
    </>
  )
}
