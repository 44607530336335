import { InteractiveCard, VideoCard } from "../container/VideoCard";

export function VideoGallery({ data = [] }) {
    return <div className="video-gallery">
        {
            data.map((video) => { return <VideoFactory videoData={video}/>
            })
        }
    </div>
}

function VideoFactory({videoData}) {
    switch (videoData.type) {
        case "interactive":
            return <InteractiveCard 
            description={videoData.description} 
            title={videoData.title} 
            thumbnail ={{coverImage : videoData.thumbnail_cover,playButton : videoData.thumbnail_play}}
            href={"/interactive/" + videoData.id}
            />
        
        case "video":
            return <VideoCard 
                description={videoData.description} 
                title={videoData.title} 
                videoUrl={videoData.videoUrl} 
                thumbnail ={{coverImage : videoData.thumbnail_cover,playButton : videoData.thumbnail_play}}
            /> 
        default:
            return <div>no type</div>
    }
     
}