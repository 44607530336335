/* eslint-disable */
import { v4 as uuidv4 } from 'uuid';
import {Howl} from 'howler';
import { hmsOrSec, hmsToSecondsOnly } from '../../utils/time';
import { relVarSound } from '../data/sound';
class Node {
    constructor(node,controller) {
        this._controller =  controller ;
        this.calledNext = false ;
        this.uniqueId = uuidv4(); 
        this.schema = node.data ;
        this.key = node.key ;
        this.nodeFlags = []
        this.divert = null ;
        this.general = null ;
        this.checkPoint = null ;
        this.blockNext = true; // default value
        this.progress = false ;
        this.extra = [];
        this.linkPath = null;
        this.ignoreCondition = () => {return false} ;
        this.ignoreTo = null ;
        this.countdown = null ;
        this.audio = null ; 
        this.spriteAfterId = null ;
        this.spriteBeforeId = null ;
        this.label = null ;
        this.external = false;
        this.init();
        if(this.progress) {
            this._controller.progress(this.key) ;
        }
    }
    init = () => {
        const content = this.schema.content ;
        this.general = content[0] ;
        for (let i = 1; i < content.length; i++) {
            const element = content[i];
            if(element.hasOwnProperty("divert")) {
                this.divert = element["divert"] ;
                continue ;
            }
            if(element.hasOwnProperty("flagName")) {
                this.nodeFlags = element["flagName"].split(",") ;
                this._controller.updateFlag(this.nodeFlags) ;
                continue ;
            }
            if(element.hasOwnProperty("blockNext")) {
                this.blockNext = element["blockNext"];
                if(element.hasOwnProperty("linkPath")) {
                    this.linkPath = element["linkPath"];
                }
                if(element.hasOwnProperty("external")) {
                    this.external = element["external"];
                }
                continue ;
            }

            if(element.hasOwnProperty("label")) {
                this.label = element["label"];
                continue ;
            }
            if(element.hasOwnProperty("checkPoint")) {
                this.checkPoint = element["checkPoint"];
                this._controller.updateInitial(this.key);
                continue ;
            }
            if(element.hasOwnProperty("progress")) {
                this.progress = element["progress"] ;
                continue ;
            }
            if(element.hasOwnProperty("extra")) {
                this.extra = element["extra"].split(",") ;
                continue ;
            }
            if(element.hasOwnProperty("audio")) {
                this.audio = {} ;
                this.audio.audio = element.audio ;
                this.audio.spriteBefore = element.spriteBefore ;
                this.audio.spriteAfter = element.spriteAfter ;
            }
            if(element.hasOwnProperty("ignoreTo")) {
                
                this.ignoreCondition = () => {
                    let condition = true ;
                    if(element.hasOwnProperty("ifCondition"))
                    condition = condition && this._controller.ifCondition(element.ifCondition.split(",")) ;
                    if(element.hasOwnProperty("notIfConditions")) 
                    condition = condition && this._controller.notIfConditions(element.notIfConditions.split(","));
                    
                    return condition ; 
                } 
                if(this.ignoreCondition()) {
                    this.ignoreTo = element["ignoreTo"] ; 
                }
                continue ;
            }
            if(element.hasOwnProperty("countdown")) {
                this.countdown = element["countdown"] ; 
            }
            // TODO : set the rest of the options
            // if(element.hasOwnProperty("xx")) {
                // logique
            // }
            
        }
    }
    beforeInvoke = () => {
        if(!this.blockNext) {
            this.next();
            console.log(this.schema,this.key,"I allow the next node to pass");
        }
    }
    onInvoke = () => {
        if(this.ignoreTo) {
            this._controller.nodeExit(this.uniqueId) ;
            this.next(this.ignoreTo);
        }
        if(this.spriteBeforeId === null && this.audio !== null && this.audio.spriteBefore) {
            this.spriteBeforeId = relVarSound.play(this.audio.spriteBefore) ;
            relVarSound.on("end",() => {this._controller.stopSound(this.spriteBeforeId);this.spriteBeforeId = null},this.spriteBeforeId);
            this._controller.playSound(this.spriteBeforeId);
        }
    };
    onDestory = () => {

    };
    onEnd = () => {
        this.next();
    };
    equal = (node) => {
        return node.key === this.key ;
    }
    next(nodeKey) {
        const to = nodeKey === undefined ? this.divert : nodeKey ;

        // if(this.checkPoint) {
        //     this._controller.progress(this.key) ;
        // }
        if(to )
        if(!this.calledNext || this.linkPath !== null) {
            this.calledNext = true ;
            
            if(this.spriteAfterId === null && this.audio !== null && this.audio.spriteAfter)
            {            
                this.spriteAfterId = relVarSound.play(this.audio.spriteAfter);
                relVarSound.on("end",() => {this._controller.stopSound(this.spriteAfterId);this.spriteAfterId = null},this.spriteAfterId);
                this._controller.playSound(this.spriteAfterId);
            }
            if(this.spriteBeforeId !== null && this.audio !== null && this.audio.spriteBefore) {
                relVarSound.stop(this.spriteBeforeId)
                this._controller.stopSound(this.spriteBeforeId);
                this.spriteBeforeId = null ;
            } 
            if(to !== null) this._controller.nodeEnter(this._controller.createNode(to));
            else this._controller.declareEnd(to);
        }
    };
}

export class TunnelNode extends Node {
        // eslint-disable-next-line no-useless-constructor
        constructor(node,controller){
            super(node,controller);
            this.blockNext = false ;
        }
}
export class VideoNode extends Node {
    // eslint-disable-next-line no-useless-constructor
    constructor(node,controller){
        super(node,controller);
        this.start = null ;
        this.end = null ;
        this.videoInit() ;
    }
    videoInit() {
        const content = this.schema.content ;
        for (let i = 1; i < content.length; i++) {
            const element = content[i];
            if(element.hasOwnProperty("start")) {
                this.start = hmsOrSec(element.start);
            }
            if(element.hasOwnProperty("end")) {
                this.end = hmsOrSec(element.end) ;
            }
        }
    }
    onEnd = () => {
        // this._controller.nodeExit(this.uniqueId) ;
        this.next();
    };
}


export class ImageNode extends Node {
    // eslint-disable-next-line no-useless-constructor
    constructor(node,controller){
        super(node,controller);
        // this.beforeInvoke = this.beforeInvoke.bind(this);
    }
    

}
export class TextNode extends Node {
    // eslint-disable-next-line no-useless-constructor
    constructor(node,controller){
        super(node,controller);
        // this.beforeInvoke = this.beforeInvoke.bind(this);
        this.action = "CONFIRM";
        this.next = this.next.bind(this);
        this.textInit() ;
    }
    textInit = () => {
        const content = this.schema.content ;
        for (let i = 1; i < content.length; i++) {
            const element = content[i];
            if(element.hasOwnProperty("action"))  { // optionSchema 
                this.action = element.action ;
            }
        }
        
    }
    next() {
        this._controller.nodeExit(this.uniqueId) ;
        super.next();
    }
    

}

export class QuizNode extends Node {
    // eslint-disable-next-line no-useless-constructor
    constructor(node,controller){
        super(node,controller);
        this.next = this.next.bind(this);

    }
    next(nodeKey) {
        this._controller.nodeExit(this.uniqueId) ;
        if(nodeKey) super.next(nodeKey);
        else super.next(); 
    }
    checkPointNext () {
       if (this.divert !== null ) this._controller.updateInitial(this.divert) 
        
        
    }

}

export class ActionNode extends Node {
    // eslint-disable-next-line no-useless-constructor
    constructor(node,controller){
        super(node,controller);
        this.next = this.next.bind(this);
        this.position = "absolute";
        this.left = "0";
        this.top  = "0";
        this.actionType = "classic";
        this.image = false ;
        this.actionInit();
    }
    actionInit = () => {
        const content = this.schema.content ;
        console.log(content);
        for (let i = 1; i < content.length; i++) {
            const element = content[i];
            if(element.hasOwnProperty("position")) this.position = element.position ;  
            if(element.hasOwnProperty("left")) this.left = element.left ;  
            if(element.hasOwnProperty("top")) this.top = element.top ; 
            if(element.hasOwnProperty("actionType")) this.actionType = element.actionType ;  
            if(element.hasOwnProperty("image")) this.image = element.image ;  
        }
    }
    next(nodeKey) {
        console.log(nodeKey);
        this._controller.nodeExit(this.uniqueId) ;
        if(nodeKey) super.next(nodeKey);
        else super.next(); 
    }


}

export class GatesNode extends Node {
    // eslint-disable-next-line no-useless-constructor
    constructor(node,controller){
        super(node,controller);
        this.options = []
        this.howlerConfig = null ;
        this.howler = null ;
        this.gateInit() ;
        this.next = this.next.bind(this);
        this.getSound = this.getSound.bind(this);
    }
    getCondition = (optionSchema) => {
        let condition = true ;
        if(optionSchema.hasOwnProperty("ifCondition"))
        condition = condition && this._controller.ifCondition(optionSchema.ifCondition.split(",")) ;
        if(optionSchema.hasOwnProperty("notIfConditions")) 
        condition = condition && this._controller.notIfConditions(optionSchema.notIfConditions.split(","));
        return condition ; 
    }
    getVisited = (optionSchema) => {
        let visited = null ;
        if(optionSchema.hasOwnProperty("visited")) {
            visited = this._controller.ifCondition(optionSchema.visited.split(","));
        }
        return visited ;
    }
    getSound = (HowlConfig={}) => {
        if(!this.howler && this.howlerConfig) {
            this.howler = new Howl({...this.howlerConfig,...HowlConfig});
        }
        return this.howler ;
    }
    getEnable = (optionSchema) => {
        let enable = null ;
        if(optionSchema.hasOwnProperty("enable")) {
            enable = this._controller.ifCondition(optionSchema.enable.split(","));
        } 
        return enable ;
    }
    gateInit = () => {
        const content = this.schema.content ;
        for (let i = 1; i < content.length; i++) {
            const element = content[i];
            if(element.hasOwnProperty("howlerConfig")) {
                this.howlerConfig = element.howlerConfig ;
            }
            if(element.hasOwnProperty("option"))  { // optionSchema 
                let condition = this.getCondition(element) ;
                let visited = this.getVisited(element) ;
                let enable = this.getEnable(element);
                let parametre = [] ;
                let sprite = null ;
                if(element.hasOwnProperty("parametre")) {
                    parametre = element.parametre.split(",") ;
                }
                this.options.push(Object.assign({},element,{uniqueId : uuidv4()},{condition,visited,enable,parametre,sprite : element["sprite"]}));
            }
        }
    }
    next(nodeKey) {
        this._controller.nodeExit(this.uniqueId,this) ;
        super.next(nodeKey);
    }

}

export default VideoNode ;

