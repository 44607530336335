import React from 'react'
import ReactPlayer from 'react-player'
import { Loader } from './loader/loader'
import Button from './action/Button'
class VideoFragment extends React.Component {
  constructor(props) {
    super(props)

    this.videoRef = React.createRef(null)
    this.progressInterval = 200

    this.state = {
      playing: true,
      stop: false,
      firstMount: true,
      current: 0,
      loading : false,
      skip : false
    }
  }

  getStart = () => {
    if (this.props.start) return this.props.start
    return 0
  }

  toEnd = () => {
    if(this.props.skip) this.videoRef.current.seekTo(this.getEnd() - 1, 'seconds')

  }
  getEnd = () => {
    if (this.props.end) return this.props.end
    if (this.videoRef.current) return this.videoRef.current.getDuration()
    return null
  }
  load = (e) => {
    if(e.type === "waiting") {
      this.setState({loading : true})
    }else if(e.type === "playing") {
      this.setState({loading : false})
    }
  }
  setPlaying = () => {
    this.setState({ playing: !this.state.playing })
  }

  pause = () => {
    this.setState({ playing: false })
  }

  stop = () => {
    this.setState({ stop: true })
  }

  start = () => {
    // this.setState({playing : true})
  }

  handleOnReady = () => {}
  onDuration = (duration) => {
    if (this.state.firstMount) {
      this.seekTo(this.getStart())
    }
  }

  onSeek = (d) => {
    if (this.state.firstMount && this.getStart() === ~~d) {
      this.setState({ firstMount: false })
    } else if (this.getStart() === d) {
      this.setState({ playing: true })
    }
    // else if(this.state.start === d)(
    //   this
    // )
  }

  componentDidUpdate(prevProps, prevState) {

    if (this.props.url && this.props.url !== prevProps.url) {
      this.setState({ firstMount: false }, () => {
        this.seekTo(this.getStart(), 'newData')
      })
    }
    if (
      (this.props.start && this.props.start !== prevProps.start) ||
      (this.props.end && this.props.end !== prevProps.end)
    ) {
      this.seekTo(this.getStart(), 'newData')
      if (this.getStart() >= this.getEnd()) {
        this.props.onTimeEnd && this.props.onTimeEnd()
      }
    }
    if(this.props.nodeVideo && prevProps.nodeVideo.uniqueId !== this.props.nodeVideo.uniqueId) {
      this.seekTo(this.getStart(), 'newData')
    }
  }

  seekTo = (time = 0, metaData) => {
    if (this.videoRef) {
      if (metaData === 'newData') {
        this.setState({ stop: false })
      }
      this.videoRef.current.seekTo(time, 'seconds')
    }
  }

  compareTime = (currentTime, toTime, intervalInSeconde) => {
    // time in seconde
    const minusToTime = toTime - intervalInSeconde / 2
    const plusToTime = toTime + intervalInSeconde / 2
    if (currentTime > minusToTime && currentTime < plusToTime) {
      return true
    }
    return false
  }

  onProgress = ({ played, playedSeconds, loaded, loadedSeconds }) => {
    this.setState({ current: playedSeconds })
    const isTime = this.compareTime(
      playedSeconds,
      this.getEnd(),
      (this.progressInterval + 200) / 1000
    )

    // skip button showo or not depending on the video
    if(this.state.skip === false && playedSeconds < this.getEnd() - 2) {
      this.setState({skip:true});
    } else if(this.state.skip === true && playedSeconds > this.getEnd() - 2) {
      this.setState({skip : false})
    }

    if (isTime && this.state.playing) {
      this.pause()
      this.stop()
      this.props.onTimeEnd && this.props.onTimeEnd()
    }
  }

  render() {
    const canPlay =
      !this.state.stop &&
      this.state.playing &&
      this.getStart() < this.getEnd() &&
      this.props.pageVisible && 
      !this.props.pause;
 

    return (
      <>
        {this.state.loading && <Loader/>  }
        {this.props.skip && this.state.skip && canPlay &&  <Button
            onClick={() => {
              this.toEnd()
            }}
            className="buttonIcon fill danger"
            style={{
              position: "absolute",
              zIndex: "999",
              top: "85%",
              right: "3%",
              maxHeight : "7%"
            }}
          >
                          <img
                src={`/interactive/button/skip.svg`}
                alt="icon"
              ></img>
            </Button>}
        <ReactPlayer
          progressInterval={this.progressInterval}
          playsinline = {true}
          width="100%"
          height="100%"
          style={{ opacity: this.state.firstMount ? 1 : 1 }}
          onSeek={this.onSeek}
          muted={this.props.mute}
          onDuration={this.onDuration}
          ref={this.videoRef}
          onProgress={this.onProgress}
          onReady={this.handleOnReady}
          url={this.props.url}
          playing={canPlay}
          onBuffer={(e) => this.load(e)}
          onBufferEnd={(e)=> this.load(e)}
        />
      </>
    )
  }
}

export default VideoFragment
