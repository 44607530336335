export function hmsToSecondsOnly(str) {
    var hms_ms = str.split('.'),
    p = hms_ms[0].split(':'),
    ms = hms_ms[1],
    s = 0, m = 1;
    if(parseInt(ms, 10)) s += (parseInt(ms, 10) / 1000);
    while (p.length > 0) {
        s += m * parseInt(p.pop(), 10);
        m *= 60;
    }

    return s;
}


export function hmssToSecondsOnly(str) {
    var p = str.split(':'),
        s = 0, m = 1;

    while (p.length > 0) {
        s += m * parseInt(p.pop(), 10);
        m *= 60;
    }

    return s;
}

export function hmsOrSec(time) {
    if(time === undefined || time === null) return time ;
    if(!isNaN(time)) return time ; 
    if(isNaN(time)) return hmsToSecondsOnly(time) ;
    
}