import React, { useEffect, useRef, useState } from "react";
import nodeHandler from "../class/nodeHandler";
import tempalteRelvar from "../class/videoRelvarData";

import template from "../class/data";
import dataQuiz from "../class/quizData";
import VideoFragment from "./videoFragment";
import "../interactive.css";
import Popup from "./Popups/Popup";
import { Countdown, CountdownDelay } from "../data/Quiz1";
import "./action/button.scss";
// import { countdownDie, relVarAmbiance, soundFx } from "../../data/sound";
import { Screen } from "./screen/Screen";
import Button from "./action/Button";
import { Howler } from "howler";
import Action from "./action";
import { QuizContainer } from "./quiz";
import { videoData } from "../class/video_data";
import { flexFontStyle } from "../../utils/flexFont";
// import background from "../../assets/background.jpg";

function Container(props) {
  useEffect(() => {
    flexFontStyle();
},[])
  function onEnd() {
    localStorage.removeItem("interactive");
    document.location.reload(true);
  }
  return <Interactive {...props} onEnd={onEnd} />;
}

class Interactive extends React.Component {
  constructor(props) {
    super(props);
    this.debug = "false";
    this.nodeHandler = new nodeHandler(videoData[`video_data_${props.id}`]);
    this.state = {
      nodeToDisplay: [],
      video: null,
      gate: null,
      canvas: null,
      image: null,
      quiz: null,
      text: null,
      action: null,
      progress: [],
      showInformation: true,
      status: "ready",
      focusUser: true,
      pageVisible: true,
      mute: true,
      pause: true,
      firstTime: true,
    };
    
    this.child = React.createRef();
    // this.nodeHandler.nodeEnter(this.nodeHandler.createNode())
    // const videoItemStorage = JSON.parse(localStorage.getItem("interactive"))  ;

    // const VideoFinished =  videoItemStorage !== null && videoItemStorage.initial === "NodeImage3"  ;
    // if(VideoFinished) {
    //   window.location.replace("/") ;
    // }
    // this.VideoFinished = VideoFinished ;
  }


  componentDidMount() {
    window.addEventListener("focus", this.onFocus);
    window.addEventListener("blur", this.onBlur);
    window.addEventListener("visibilitychange", this.onVisibility);
    this.nodeHandler.subscribeObserver(this.updateNodeToDisplay);
    this.nodeHandler.nodeEnter(this.nodeHandler.createNode());
    // relVarAmbiance.play();
  }

  componentWillUnmount() {
    
    window.removeEventListener("focus", this.onFocus);
    window.removeEventListener("blur", this.onBlur);
    window.removeEventListener("visibilitychange", this.onVisibility);
    this.nodeHandler.unsubscribeObserver(this.updateNodeToDisplay);
    // relVarAmbiance.stop();
    this.setState({ firstTime: true });
  }

  onFocus = () => {
    this.setState({ focusUser: true });
  };

  onBlur = () => {
    this.setState({ focusUser: false });
  };

  onVisibility = () => {
    if (document.visibilityState == "visible") {
      this.setState({ pageVisible: true });
      console.log("tab is active");
    } else {
      this.setState({ pageVisible: false });
      console.log("tab is NOT active");
    }
  };

  updateNodeToDisplay = ([event, nodeToDisplay, progress, status]) => {
    let video = null;
    let image = null;
    let gate = null;
    let canvas = null;
    let quiz = null;
    let text = null;
    let action = [];

    nodeToDisplay.forEach((el) => {

      if (el.schema.node_type === "video") {
        video = el;
        canvas = el;
        image = null;
        action = [];
      } else if (el.schema.node_type === "gate") {
        gate = el;
        canvas = el;
        text = null;
        action = [];
      } else if (el.schema.node_type === "image") {
        image = el;
        canvas = el;
        video = null;
        action = [];
      } else if (el.schema.node_type === "quiz") {
        quiz = el;
        canvas = el;
        gate = null;
        text = null;
        action = [];
      } else if (el.schema.node_type === "text") {
        text = el;
        canvas = el;
        quiz = null;
        gate = null;
        action = [];
      } else if (el.schema.node_type === "action") {
        action = [...action,el];
        canvas = el;
      } else if (el.schema.node_type === "tunnel") {
        action = [];
      }
    });
    this.setState({
      nodeToDisplay,
      video,
      gate,
      image,
      canvas,
      quiz,
      progress,
      status,
      text,
      action,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.status === "end" && this.state.status !== prevState.status) {
      this.props.onEnd();
    }
    if (this.state.mute !== undefined && this.state.mute !== prevState.mute) {
      Howler.mute(this.state.mute);
    }
    if (
      this.state.pause !== undefined &&
      this.state.pause !== prevState.pause
    ) {
      if (this.state.pause) {
        this.nodeHandler.pauseAllSounds();
      } else {
        this.nodeHandler.resumeAllSounds();
      }
    }
  }

  render() {
    const { gate, video, image, quiz, progress, canvas, text, action } =
      this.state;
    const hiddenPlayButton = gate || quiz;
    const currentNode =
      this.state.nodeToDisplay.length !== 0 &&
      this.state.nodeToDisplay[this.state.nodeToDisplay.length - 1].key;
    const buttonTheme =
      currentNode === "NodeActionEnd" ||
      currentNode === "NodeVideoPreEndContinue" ||
      currentNode === "NodeVideoPreEndRefaire" ||
      currentNode === "NodeVideoEnd";
    const buttonThemeClass = buttonTheme ? "dark" : "light";
    // if(this.VideoFinished) return null ;
    return (
      <>
        {/* {this.state.firstTime && (
          <img
            style={{
              objectFit: "cover",
              position: "absolute",
              height: "100%",
              width: "100%",
              zIndex: "99",
            }}
            src={"background"}
            alt=""
          />
        )} */}
        {this.state.firstTime && (
          <Button
            onClick={() => {
              this.setState({ mute: false, pause: false, firstTime: false });
            }}
            className="buttonText fill danger"
            style={{
              position: "absolute",
              zIndex: "999",
              top: "81%",
              left: "57%",
              fontSize : "0.3em"
            }}
          >
            <div>
              Démarrer le cas
            </div>
          </Button>
        )}

        {!this.state.firstTime && !buttonTheme && (
          <>
            <Button
              className={`buttonIcon fill danger ${buttonThemeClass}`}
              onClick={() => {
                this.setState({ mute: !this.state.mute });
              }}
              style={{
                position: "absolute",
                zIndex: "999",
                top: "2%",
                right: "3%",
                width: "4%"
              }}
            >
              <img
                src={`/interactive/button/${this.state.mute ? "nosound" : "sound"}.png`}
                alt="icon"
              ></img>
            </Button>

            {!hiddenPlayButton && !buttonTheme && (
              <Button
                className={`buttonIcon fill danger ${buttonThemeClass}`}
                onClick={() => {
                  this.setState({ pause: !this.state.pause });
                }}
                style={{
                  position: "absolute",
                  zIndex: "999",
                  top: "2%",
                  right: "8%",
                  width: "4%",
                }}
              >
                <img
                  src={`/interactive/button/${this.state.pause ? "play" : "pause"}.png`}
                  alt="icon"
                ></img>
              </Button>
            )}
          </>
        )}
        
        {/* <div style={{ position: "absolute", zIndex: 999,left: "35%" }}>
          {this.state.nodeToDisplay.length !== 0 &&
            this.state.nodeToDisplay[this.state.nodeToDisplay.length - 1].key}
        </div> */}
            
        {image && (
          <Screen>
            {/* <h1>{image.key}</h1>  */}
            {/* <div>{JSON.stringify(image.schema,null,2)}</div>   */}
            <img
              style={{ height: "100%", width: "100%" }}
              src={image.general}
              alt=""
            ></img>
          </Screen>
        )}
        {video && (
          <Screen>
            {/* <h1 style={{position :"absolute"}} >{video.key}</h1>  */}
            {/* <div style={{position :"absolute"}}>
                    {JSON.stringify(video.schema,null,2)}
                    </div>   */}

            <VideoFragment
              audio={video.audio}
              start={video.start}
              end={video.end}
              url={video.general}
              nodeVideo={video}
              onTimeEnd={() => video.onEnd()}
              pageVisible={this.state.pageVisible}
              pause={this.state.pause}
              mute={this.state.mute}
              skip = {true}
            />

            {/* {
                          (video.key === "NodeVideoPreEndContinue" || video.key === "NodeVideoPreEndRefaire") && <div className="containerButtonEnd" style={{position : "absolute"}}> 
                                          <a style={{animationDelay : "0ms"}} onClick={() => soundFx.play("click")} className="buttonEnd scale-in-center" href="https://assets.gskstatic.com/pharma/GSKpro/Morocco/Relvar/relvar_ellipta_mentions_legales_completes_version_fs_jan_2021_new.pdf" rel="noreferrer" target="_blank">Accéder aux ML</a>
                                          <div style={{animationDelay : "100ms"}} className="buttonEnd scale-in-center" onClick={() => {soundFx.play("click");video.next("NodeVideoEnd")}}>Refaire l'expérience</div>
                                    </div>
                                    }  */}
          </Screen>
        )}
        {gate && !this.state.firstTime && (
          <Screen className="gateBackground">
            <h1>{gate.key}</h1> 
            <div style={{position : "absolute",fontSize : "25px"}}>{JSON.stringify(gate.schema,null,2)}</div>  
            <GateContainer
              extra={gate.extra}
              options={gate.options}
              howl={gate.getSound}
              countdown={gate.countdown}
              onClick={gate.next}
            />
          </Screen>
        )}
        {text && (
          <Screen>
            <Popup
              buttonStyle={{ width: "13vw", maxWidth: "300px" }}
              buttonText={text.action}
              next={() => text.next()}
              Nobackground={true}
              style={{ minHeight: "10%" }}
              jauge={false}
            >
              {text.general}
            </Popup>
          </Screen>
        )}

        {quiz && !this.state.firstTime && (
          // onClick={() => quiz.next()} className="QuizHolder"
          <Screen>
            {/* <h1 onClick={() => quiz.next()}>{quiz.key}</h1>
            <div>{JSON.stringify(quiz.schema, null, 2)}</div> */}
            <QuizContainer next={quiz.next} style={dataQuiz[`interactive_quiz_${this.props.id}`][quiz.general].style} type={dataQuiz[`interactive_quiz_${this.props.id}`][quiz.general].type} question ={dataQuiz[`interactive_quiz_${this.props.id}`][quiz.general].question} options = {dataQuiz[`interactive_quiz_${this.props.id}`][quiz.general].options} actions = {dataQuiz[`interactive_quiz_${this.props.id}`][quiz.general].actions}/>
          </Screen>
        )}

        {action && action.length !== 0 && !this.state.firstTime && (
          <Screen>
            {
            action.map((a) => <Action
              style={{
                position: "absolute",
                top: a.top,
                left: a.left,
                fontSize : "0.3em"
              }}
              key={a.uniqueId}
              linkPath = {a.linkPath}
              external = {a.external}
              extra={a.extra}
              onEnd={a.next}
              image={a.image}
              text={a.general}
              actionType={a.actionType}
              pause = {this.state.pause}
            />)

            }
           
          </Screen>
        )}

        {/* {this.state.nodeToDisplay.length !== 0 && <Label text={canvas.label} /> }  */}

        {/* {this.debug === "true" && (
          <div style={{ position: "fixed", bottom: "3%", left: "10%" }}>
            <button
              onClick={() =>
                this.nodeHandler.nodeEnter(this.nodeHandler.createNode())
              }
            >
              start
            </button>
            <button onClick={() => this.nodeHandler.loadState()}>load</button>
            {"  "}
            <button onClick={() => this.nodeHandler.clearState()}>clear</button>
            {"  "}
            <span>{this.state.status}</span>
            {"  "}
            <span>{this.state.mute ? "mute" : "sound"}</span>
            {"  "}
            <span>{this.state.pause ? "pause" : "play"}</span>
          </div>
        )} */}
      </>
    );
  }
}

function GateContainer(props) {
  const [id, setId] = useState(0);
  return <Gate key={id} {...props} restart={() => setId((id) => id + 1)} />;
}
function Gate({ options, howl, onClick, countdown, restart, extra = [] }) {
  const animationDelay = 50;
  const [information, setInformation] = useState(false);
  const [timeoutAnimation, setTimeoutAnimation] = useState(false);
  const [textInfomrmation, setTextInfomration] = useState("");
  const [canClick, setCanClick] = useState(true);
  const [pauseCountDown, setPauseCountdown] = useState(false);
  // const refSoundCountdDown = useRef(countdownDie);
  useEffect(() => {
    return () => {
      // stopSounds();
    };
  }, []);
  function playSprite(sprite) {
    // if (!sprite || !canClick) return;
    // const audio = howl();
    // if (!audio) return;
    // setCanClick(false);
    // stopSounds();
    // refSoundCountdDown.current.stop();
    // audio.play(sprite);
  }
  // function stopSounds() {
  //   console.log("stop that shit");

  //   const audio = howl();
  //   if (audio) audio.stop();

  //   refSoundCountdDown.current.stop();
  // }
  function timeEnd() {
    setCanClick(false);
    // soundFx.play("wrongAnswer");
    // stopSounds();
    setTimeoutAnimation(true);
    setTimeout(() => {
      setTimeoutAnimation(false);
      // if (refSoundCountdDown.current.once === true) restart();
    }, 1000);

    // if (refSoundCountdDown.current.once === false)
    //   refSoundCountdDown.current
    //     .onend(() => {
    //       restart();
    //     })
    //     .play();

    // refSoundCountdDown.current.play();
  }
  function getVisitedClass(visited) {
    // can be null or true or false
    if (visited === null) {
      return "";
    } else if (visited === true) {
      return "visited";
    } else if (visited === false) {
      return "";
    } else {
      return "";
    }
  }
  function getEnableClass(enable) {
    if (enable === null) {
      return "";
    } else if (enable === true) {
      return "enable";
    } else if (enable === false) {
      return "";
    } else {
      return "";
    }
  }
  function getDisableClass(disable) {
    if (!disable) return "disabled";
    return "";
  }
  function click(condition, enable, visited, linkPath, paramatre, e) {
    if (!enable) return;
    if (!canClick) return;
    if (!onClick) return;
    if (visited && !enable) return;
    if (condition) {
      if (paramatre.includes("true")) {
        // soundFx.play("correctAnswer");
      } else if (paramatre.includes("false")) {
        // soundFx.play("wrongAnswer");
      } else {
        // soundFx.play("click");
      }
      setCanClick(false);
      setPauseCountdown(true);
      setTimeout(() => {
        onClick(linkPath);
      }, 1000);
      e.target.classList.add("transitionClick");
    } else {
      // setTextInfomration(informationData.blocked)
      // setInformation(true)
    }
  }

  return (
    <>
      {information && (
        <Popup
          buttonStyle={{ width: "13vw", maxWidth: "300px" }}
          buttonText={"BACK TO SELECTION"}
          next={() => setInformation(false)}
          Nobackground={true}
          style={{ minHeight: "10%" }}
          jauge={false}
        >
          {textInfomrmation}
        </Popup>
      )}
      {!information && (
        <Popup Nobackground={true} button={false}>
          <ul className={`gateMenu ${extra.join(" ")}`}>
            {options.map((el, i) => {
              return (
                <li
                  className={`button scale-in-center ${getDisableClass(
                    el.condition
                  )} ${getVisitedClass(el.visited)} ${getEnableClass(
                    el.enable
                  )} ${el.parametre.join(" ")}`}
                  key={el.uniqueId}
                  onClick={(e) => {
                    click(
                      el.condition,
                      el.enable,
                      el.visited,
                      el.linkPath,
                      el.parametre,
                      e
                    );
                    // playSprite(el.sprite);
                  }}
                  style={{ animationDelay: `${i * animationDelay}ms` }}
                >
                  {el.option}
                </li>
              );
            })}
          </ul>
          <div className="GateTimerContainer">
            {countdown && <button onClick={restart}>restart</button>}
            {countdown && (
              <CountdownDelay
                wait={options.length * animationDelay}
                time={countdown}
                timeoutAnimation={timeoutAnimation}
                pauseState={pauseCountDown}
                onExpire={() => {
                  timeEnd();
                }}
              />
            )}
          </div>
        </Popup>
      )}
    </>
  );
}
export default Container;

function Eye({ toggle, show }) {
  return (
    <div
      style={{
        cursor: "pointer",
        fontSize: "1.3em",
        position: "absolute",
        top: "3%",
        right: "3%",
        color: "#ffbe00",
        textDecorationLine: "underline",
        zIndex: "99",
      }}
      onClick={toggle}
    >
      {show ? "😅" : "🤔"}
    </div>
  );
}

