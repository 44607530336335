// import { Circle } from "rc-progress";
import LongClick from "./LongClickButton";
import { relVarSound } from "../../data/sound";

function HoldAction({ onEnd = () => {}, text = "" }) {
  return (
    <LongClick
      threshold={3}
      onFail={() => relVarSound.play("inhalation3")}
      onEnd={onEnd}
    >
      {(timeHold, threshold, fail) => {
        return (
          <div
            className={`${fail ? "jello-horizontal" : ""}`}
            style={{
              cursor: "pointer",
              position: "absolute",
              width: "4.1em",
              height: "4.1em",
              bottom: "1%",
              left: "8%",
            }}
          >
            <div
              style={{
                position: "absolute",
                height: "85%",
                width: "85%",
                top: "50%",
                left: "50%",
                transform: "translate(-50%,-50%)",
                borderRadius: "50%",
                background: "rgba(255,255,255,0.55)",
                boxShadow: "rgb(255 255 255 / 55%) 0px 0px 10px 3px",
              }}
            >
              <h5
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%,-50%)",
                  textAlign: "center",
                  fontSize: "0.6em",
                  color: "#2B658A",
                }}
              >
                {text} {parseFloat(timeHold).toFixed(1)}s
              </h5>
            </div>
            {/* <Circle
              percent={(timeHold / threshold) * 100}
              strokeWidth="4"
              strokeLinecap="round"
              strokeColor={"#4F9628"}
            />{" "} */}
          </div>
        );
      }}
    </LongClick>
  );
}

export default HoldAction;
