import { useEffect, useState } from 'react';
import useLongPress from '../../../hooks/useLongPress';

const LongClick = ({children,threshold = 5,onFail= () => {},onEnd= () => {}   }) => {
    const [pressClickTime,setpressClickTime] = useState(0);
    const [fail,setFail] = useState(null);
    const onFaill = () => {
      setFail(true);
      onFail();
    }
    useEffect(() => {
      if(fail === true) {
        let timeout = setTimeout(() => {
          setFail(false);
        }, 1000);
        return () => clearTimeout(timeout);
      }
    },[fail])
    const bind = useLongPress({
        callback : onEnd ,
        update :  (current) => {setpressClickTime(current)},
        onFail : onFaill,
        threshold,
        enable : true 
    });
    
    return <div className='longclick' {...bind}>{children(pressClickTime,threshold,fail)}</div>;
  };

export default LongClick ;